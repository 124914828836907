<template>
  <div class="flex-row">
    <b-modal id="buy-account" modal-class="modal-primary" title="Buy New Account" v-if="selectedGroup">
      <validation-observer ref="orderFormValidation">
        <b-form
          class="mt-2"
          @submit.prevent
        >
          <b-row>
            <b-col>
              <b-form-group :label="'Quantity (max ' + selectedGroup.inventory + ')'">
                <validation-provider
                  #default="{ errors }"
                  name="quantity"
                  rules="required"
                  min="1"
                  :max="selectedGroup.inventory"
                >
                  <b-form-input
                    v-model.number="placeOrderForm.quantity"
                    type="number"
                    min="0"
                    :max="selectedGroup.inventory"
                    name="quantity"
                    oninput="validity.valid||(value='');"
                    :state="errors.length > 0 ? false : null"
                  />
                  <span class="text-danger">{{ errors[0] }}</span>
                </validation-provider>
              </b-form-group>
              <b-form-group label="Account Type">
                <validation-provider
                  name="account type" rules="required" #default="{ errors }"
                >
                  <b-form-input
                    :value="selectedGroup.name"
                    type="text"
                    :disabled="true"
                  />
                  <span class="text-danger">{{ errors[0] }}</span>
                </validation-provider>
              </b-form-group>
              <b-form-group
                label="Notes"
                label-for="notes"
              >
                <b-form-textarea
                  v-model="placeOrderForm['note']"
                  placeholder="Notes"
                  name="notes"
                />
              </b-form-group>
              <b-form-group v-if="placeOrderForm.quantity && placeOrderForm.quantity < selectedGroup.inventory">
                <h4>Price: {{ placeOrderForm.quantity * selectedGroup.price }} €</h4>
              </b-form-group>
            </b-col>
          </b-row>
        </b-form>
      </validation-observer>
      <template #modal-footer>
        <b-button
          v-ripple.400="'rgba(255, 255, 255, 0.15)'"
          variant="secondary"
          @click="selectedGroup = null"
        >Close</b-button>
        <b-button
          v-ripple.400="'rgba(255, 255, 255, 0.15)'"
          variant="primary"
          @click="validateForm()"
          :disabled="!placeOrderForm.quantity || placeOrderForm.quantity >= selectedGroup.inventory"
        >Place an order</b-button>
      </template>
    </b-modal>
    <div class="mb-1">
      <div class="row">
        <v-select class="col-lg-3 col-md-3 mt-1"
          placeholder="Niche"
          :options="niches"
          v-model="selectedFilters.niche">
        </v-select>
        <v-select class="col-lg-3 col-md-3 mt-1"
          placeholder="Geo Location"
          :options="geoLocations"
          v-model="selectedFilters.location">
        </v-select>
        <v-select class="col-lg-3 col-md-3 mt-1"
          placeholder="Language"
          :options="languages"
          v-model="selectedFilters.language"
        ></v-select>
        <div class="col-lg-3 col-md-3 mt-1 display-flex">
          <div class="col-lg-4 col-md-4 side-input-text"><span>Price</span></div>
          <input type="number" v-model="selectedFilters.priceMin" oninput="validity.valid||(value='');" min="0" placeholder="Min" class="col-lg-4 col-md-4 col-xs-6 pretty-input">
          <input type="number" v-model="selectedFilters.priceMax" min="0" oninput="validity.valid||(value='');" placeholder="Max" class="col-lg-4 col-md-4 col-xs-6 pretty-input rounded-right-corners">
        </div>
      </div>
    </div>
    <b-card>
      <div class="custom-search d-flex flex-row justify-content-end align-items-center">
        <b-form-group>
          <div class="d-flex align-items-center">
            <label class="mr-1">Search</label>
            <b-form-input
              v-model="searchTerm"
              placeholder="Search"
              type="text"
              class="d-inline-block"
            />
          </div>
        </b-form-group>
      </div>
      <vue-good-table
        :columns="columns"
        :rows="filterGroupTable"
        :rtl="false"
        :search-options="{
          enabled: true,
          externalQuery: searchTerm }"
        :pagination-options="{
          enabled: true,
          perPage:pageLength
        }"
      >
        <template
          slot="table-row"
          slot-scope="props"
        >
          <span v-if="props.column.field === 'status'">
            <b-badge :variant="'light-info'">
              {{ props.row.status }}
            </b-badge>
          </span>
          <span v-else-if="props.column.field === 'order'">
            <b-button
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              variant="primary"
              class="mx-auto d-flex"
              :disabled="props.row.inventory <= 0"
              @click="selectedGroup = props.row"
              v-b-modal.buy-account
            >Buy</b-button>
          </span>
          <span v-else>
            {{ props.formattedRow[props.column.field] }}
          </span>
        </template>

        <!-- pagination -->
        <template
          slot="pagination-bottom"
          slot-scope="props"
        >
          <div class="d-flex justify-content-between flex-wrap">
            <div class="d-flex align-items-center mb-0 mt-1">
              <span class="text-nowrap ">
                Showing 1 to
              </span>
              <b-form-select
                v-model="pageLength"
                :options="['10','25','50']"
                class="mx-1"
                @input="(value)=>props.perPageChanged({currentPerPage:value})"
              />
              <span class="text-nowrap"> of {{ props.total }} entries </span>
            </div>
            <div>
              <b-pagination
                :value="1"
                :total-rows="props.total"
                :per-page="pageLength"
                first-number
                last-number
                align="right"
                prev-class="prev-item"
                next-class="next-item"
                class="mt-1 mb-0"
                @input="(value)=>props.pageChanged({currentPage:value})"
              >
                <template #prev-text>
                  <feather-icon
                    icon="ChevronLeftIcon"
                    size="18"
                  />
                </template>
                <template #next-text>
                  <feather-icon
                    icon="ChevronRightIcon"
                    size="18"
                  />
                </template>
              </b-pagination>
            </div>
          </div>
        </template>
      </vue-good-table>
    </b-card>
  </div>
</template>

<script>
import {
  BButton,
  BForm,
  BFormSelect,
  BFormGroup,
  BRow,
  BCol,
  BCard,
  BFormTextarea,
  BFormInput,
  BPagination,
} from 'bootstrap-vue'

import { VueGoodTable } from 'vue-good-table'
import ToastificationContent from '@core/components/toastification/ToastificationContent'
import Ripple from 'vue-ripple-directive'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import { required } from '@validations'

export default {
  name: 'BuyNewAccount',
  components: {
    BButton,
    BForm,
    BFormSelect,
    BFormGroup,
    BFormTextarea,
    BFormInput,
    BRow,
    BCol,
    BCard,
    BPagination,
    ValidationProvider,
    ValidationObserver,
    VueGoodTable,
  },
  directives: {
    Ripple,
  },
  data () {
    return {
      placeOrderForm: {
        quantity: null,
      },
      selectedGroup: null,
      groups: [],
      columns: [
        {
          label: 'Name',
          field: 'name',
        },
        {
          label: 'Niche',
          field: 'niche',
        },
        {
          label: 'Language',
          field: 'language',
        },
        {
          label: 'Geo',
          field: 'country',
        },
        {
          label: 'Price',
          field: 'price',
        },
        {
          label: 'Maintenance',
          field: 'maintenancePrice',
        },
        {
          label: 'SafeSite',
          field: 'safeSitePrice',
        },
        {
          label: 'Inventory',
          field: 'inventory',
        },
        {
          label: 'Order',
          field: 'order',
        },
      ],
      pageLength: 10,
      props: [],
      geoLocations: [],
      languages: [],
      niches: [],
      selectedFilters: {},
      searchTerm: ''
    }
  },
  computed: {
    filterGroupTable () {
      let newGroups = this.groups
      if (this.selectedFilters['language']) {
        newGroups = newGroups.filter(g => g.language === this.selectedFilters.language)
      }
      if (this.selectedFilters['niche']) {
        newGroups = newGroups.filter(g => g.niche === this.selectedFilters.niche)
      }
      if (this.selectedFilters['location']) {
        newGroups = newGroups.filter(g => g.country === this.selectedFilters.location)
      }
      if (this.selectedFilters['priceMin'] && this.selectedFilters['priceMin'].length) {
        newGroups = newGroups.filter(g => g.price >= this.selectedFilters.priceMin)
      }
      if (this.selectedFilters['priceMax'] && this.selectedFilters['priceMax'].length) {
        newGroups = newGroups.filter(g => g.price <= this.selectedFilters.priceMax)
      }
      return newGroups
    }
  },
  mounted () {
    this.$store.dispatch('fetchGroups').then(() => {
      this.groups = this.$store.state.accountsStore.groups
      this.geoLocations = [...new Set(this.groups.map(g => g.country))]
      this.languages = [...new Set(this.groups.map(g => g.language).filter(l => l.length > 0))]
      this.niches = [...new Set(this.groups.map(g => g.niche).filter(niche => niche.length > 0))]
    }).catch(error => {
      let msg = error.message
      if (error.response) {
        msg = error.response.data.msg
      }
      this.$toast({
        component: ToastificationContent,
        props: {
          title: msg,
          icon: 'InfoIcon',
          variant: 'danger',
        },
      }, {
        position: 'top-center',
      })
    })
  },
  methods: {
    validateForm () {
      this.$refs.orderFormValidation.validate().then(success => {
        if (success) {
          this.placeAccountOrder()
        } else {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: 'Please fill all required fields',
              icon: '',
              variant: 'warning',
            },
          })
        }
      })
    },
    placeAccountOrder () {
      const data = {
        note: this.placeOrderForm.note,
        quantity: this.placeOrderForm.quantity,
        group: this.selectedGroup.id,
      }

      this.$store.dispatch('buyNewAccount', { ...data }).then(() => {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'Account Order Placed',
            icon: 'CheckIcon',
            variant: 'success',
          },
        })
        this.$router.push('/orders')
      }).catch(e => {
        const msg = e.response.data
        this.$toast({
          component: ToastificationContent,
          props: {
            title: msg.error.msg || 'Error placing order',
            icon: 'InfoIcon',
            variant: 'danger',
          },
        })
      })
    },
  },
}
</script>
